import { tokenName } from "../../config"
import { getLocal } from "../../utils/store"
import { messageHttpConfig } from "../config"
import http from "../../utils/http"

const getUnreadNum =async ()=>{
    const url = messageHttpConfig.unreadNum
    const token = getLocal(tokenName)
    
    let result = {}
    try {
        result = await http.post(url,{"token":token})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    return result;
}
const getReceivedLike =async (pageNum,pageSize)=>{
    const url = messageHttpConfig.receivedlike
    const token = getLocal(tokenName)
    
    let result = {}
    try {
        result = await http.post(url,{"token":token,"page_num":pageNum,"page_size":pageSize})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    return result;
}
const getReceivedComment =async (pageNum,pageSize)=>{
    const url = messageHttpConfig.receivedcomment
    const token = getLocal(tokenName)
    
    let result = {}
    try {
        result = await http.post(url,{"token":token,"page_num":pageNum,"page_size":pageSize})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    return result;
}


export {
    getReceivedComment,
    getReceivedLike,
    getUnreadNum
}