import { tokenName } from "../config";
import { getLocal } from "../utils/store";

//短视频服务
const shortVideoRoutesPath={
    shortVideoHome:"/shortv",//主页
    shortVideoUpload:"/shortv/upload/publish",//上传
    shortCoverChange:"/shortv/upload/cover",//封面修改
}
//长视频服务
const videoRoutesPath={
    videoHome:"/video",//主页
    videoUpload:"/video/upload/publish",//上传
    videoCoverChange:"/video/upload/cover",//封面修改
    detail:"/video/:id"
}

const routePath={
    home:'/home',//主页
    //mblog
    microBlog:'/home/mblog',
    microBlogDetail:'/home/mblog/detail/:id',

    login:'/login',//登录
    loginPwd:'/loginpwd',//密码登录
    upload:'/upload',//内容上传
    message:'/msg',//内容上传
    preview:'/preview',//图片预览

    //我的主页
    mine:'/user/self',//主页
    minesetting:'/user/setting',//用户信息列表

    //用户页
    userSpace:'/user/:id',//用户页面
    

    headimg:"/headimg",//头像页
    headimgedit:'/headimg/edit', //头像裁剪
    backgroundimg:'/background',//背景图
    backgroundimgEdit:'/background/edit',//背景图修改

    receivedComment:'/msg/comment/received',//收到的评论
    receivedLike:'/msg/like/received',//收到的点赞
    dongtai:'/dongtai',//动态


    //
    jubao:"/report",
    about:"/about",
    setting:"/setting",
    ...shortVideoRoutesPath,
}



function toWhere(){
    const select = getLocal("select")
    if(!select){
        return routePath.home
    }
    return select
}

//获得拼接后的mblog详情页链接
const getMBlogDetailsUrl = (id)=>{
    return "/home/mblog/detail/"+id
}
//获得拼接后的用户主页
const getUserSpaceUrl = (id)=>{
    return "/user/"+id
}

//获得视频详情页
const getVideoDetailsUrl = (id)=>{
    return "/video/"+id
}

//需要登录的路径
const needLoginPath = (path)=>{
    let token = getLocal(tokenName)
    const needLogin = [routePath.dongtai,routePath.upload,routePath.message,routePath.mine]
    if((!token||token==="")&&needLogin.includes(path)){
        return routePath.login
    }
    return path
}
export default routePath;

export {
    toWhere,
    getMBlogDetailsUrl,
    getUserSpaceUrl,
    needLoginPath,
    getVideoDetailsUrl,
    routePath,
    shortVideoRoutesPath,
    videoRoutesPath,
    

}