import { Button, Form,Input,DotLoading } from "antd-mobile"
import styles from './login.module.less'
import { useNavigate } from "react-router-dom";

import routePath from "../../routers/config";
import { loginPwd } from "../../api/auth/auth";
import { setLocal } from "../../utils/store";
import { Eye,EyeInvisibleOutline } from "../../compoents/IconFonts/iconfont";
import { tokenName } from "../../config";

import { useState } from "react";

const Login = ()=>{
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [loadingVisiible, setLoadingVisiible] = useState(false)
    const [form] = Form.useForm();
    const onSubmit =async ()=>{
        if(loadingVisiible){
            return
        }
        const values = form.getFieldsValue();
        let r = /1[0-9]\d{9}$/
        if(!r.test(values.phone)){
            form.setFields([{'name':"phone","errors":['请输入正确手机号']}])
            return
        }
        const pwR = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*.#?&])[A-Za-z\d@$!%*.#?&]{8,18}$/
        if(!pwR.test(values.pwd) ){
            form.setFields([{'name':"pwd","errors":['密码必须跑含字符和数字和特殊字符且8到18位']}])
            return
        }

        console.log(values)
        setLoadingVisiible(true)
        loginPwd(values['phone'],values['pwd']).then((data)=>{
            setLoadingVisiible(false)
            if(data.status!==0){
                form.setFields([{'name':"pwd","errors":[data.msg]}])
            }else{
                console.log(data)
                setLocal(tokenName,data.data.token)
                navigate(routePath.home,{ replace: true })
            }
            
        });
    }

    function to(path){
        navigate(path);
    }
    return <div >
        <div className={styles.lable}><div>密码登录</div></div>
        <Form form={form} 
        requiredMarkStyle="none" 
        ayout="vertical" 
        mode='card' 
        
        footer={<Button block color='primary' onClick={()=>{onSubmit()}} size='large'>
            登录{loadingVisiible?<DotLoading />:null}
        </Button>}>
            <Form.Item name='phone' 
            required
            label='手机号' 
            rules={[
                {min:11},
            ]}
            >
                <Input placeholder='请输入手机号' />
            </Form.Item>
            <Form.Item name='pwd'
            
            rules={[
                {min:8,max:18}
            ]}
            label='密码' 
            extra={<div className={styles.eye}>{!visible ? (<div onClick={() => setVisible(true)}><EyeInvisibleOutline fontSize='0.5rem'/></div>) : (<div onClick={() => setVisible(false)}><Eye fontSize='0.5rem'/> </div>)}</div>}>
                <Input placeholder='请输入密码' type={visible ? 'text' : 'password'} />
            </Form.Item>
        </Form>
        <div className={styles.other}>
            <span onClick={()=>{to(routePath.login)}}>验证码登录</span>
        </div>
    </div>
}

export default Login;