import { userHttpConfig } from "../config"
import {http,postFile} from "../../utils/http"
import { getLocal } from "../../utils/store"
import { tokenName } from "../../config"
const getUserData = async()=>{
    const url = userHttpConfig.userinfo
    const token = getLocal(tokenName)
    console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}
const getUserDataByUserId = async(userid)=>{
    userid = parseInt(userid)
    const url = userHttpConfig.otherUser
    const token = getLocal(tokenName)
    console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,userid:userid})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}

const follow = async(userid)=>{
    userid = parseInt(userid)
    const url = userHttpConfig.follow
    const token = getLocal(tokenName)
    console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,follow_userid:userid})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}

const unfollow = async(userid)=>{
    userid = parseInt(userid)
    const url = userHttpConfig.unfollow
    const token = getLocal(tokenName)
    console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,follow_userid:userid})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}

//更新头像
const updateHeadimg = async(file)=>{
    const url = userHttpConfig.updateHeadimg
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await postFile(url,{"token":token,file:file})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}
//更新出生日期
const updateBirthday = async(birthday)=>{
    const url = userHttpConfig.updateBirthday
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,birthday:birthday})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}
//更新出生日期
const updateSex = async(sex)=>{
    const url = userHttpConfig.updatesex
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,sex:sex})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}
//更新出生日期
const updateNickname = async(nickname)=>{
    const url = userHttpConfig.updateNickname
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,nickname:nickname})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}
//更新简介
const updateRemark = async(remark)=>{
    const url = userHttpConfig.updateRemark
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,remark:remark})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}
//更新帐号
const updateAccountName = async(accountname)=>{
    const url = userHttpConfig.accountName
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await http.post(url,{"token":token,account_name:accountname})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}

//更新背景图
const updateBackgroundimg = async(file)=>{
    const url = userHttpConfig.updateBackgroundimg
    const token = getLocal(tokenName)
    // console.log("token",token)
    let result={};
    try {
        result = await postFile(url,{"token":token,file:file})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result

}

export{
    getUserData,
    follow,unfollow,
    getUserDataByUserId,
    updateBackgroundimg,
    updateSex,
    updateAccountName,
    updateNickname,
    updateRemark,
    updateBirthday,
    updateHeadimg
}