/*
 * @Author: shangshanzhishui photoncomputer@163.com
 * @Date: 2023-06-29 11:07:38
 * @LastEditors: shangshanzhishui photoncomputer@163.com
 * @LastEditTime: 2023-07-15 17:36:09
 * @FilePath: \myapp\src\utils\http.js
 * @Description: 网络请求
 */
import qs from "qs";

const http = async function(config){
    if(config===null || config===undefined) config = {};
    config = Object.assign({
        url:'',
        method:'GET',
        credential:'include',
        headers:null,
        body:null,
        params:null,
        responseType:'json',
        signal:null
    },config);
    if (!config.url) throw TypeError('请填写需要请求的地址');
    if (config.headers===null || config.url ===undefined) config.headers={};
    let {url,method,credential,headers,body,params,responseType,signal} = config;
    if(params){
        url += `${url.includes('?') ? '&' : '?'}${qs.stringify(params)}`;
    }
    if (body&&typeof body==='string'){
        headers['Content-type']='application/json';
    }
    let token = localStorage.getItem("token");
    if (token){
        headers['authorization'] = token;
    }
    method = method.toUpperCase();
    config = {
        method,
        credential,
        headers,
        cache:'no-cache',
        signal

    };
    if (['PUT','POST','PATCH'].includes(method)&&body){
        config.body = JSON.stringify(body);
    }
    // console.log("config",config)
    return fetch(url,config).then(response=>{
            let {status,statusText} = response;
            // console.log('response',(/^(2|3)\d{2}$/.test(status)),status)
            if(/^(2|3)\d{2}$/.test(status)){
                let result;
                switch (responseType.toLowerCase()) {
                    case 'text':
                        result = response.text();
                        break;
                    case 'arraybuffer':
                        result = response.arrayBuffer();
                        break;
                    case 'blob':
                        result = response.blob();
                        break;
                
                    default:
                        result = response.json();
                }
                return result;
            }
            return Promise.reject({
                status:1,
                msg:'请求出错'
            });

        }).catch(err=>{
            return Promise.reject(err)
        });
}

let method = ["GET","HEAD","DELETE","OPTIONS"];
method.forEach(element => {
    http[element.toLowerCase()]=function(url,config){
        if(config===null || config===undefined) config = {};
        config['url']=url;
        config['method']=element;
        return http(config);
    }
});
let method2 = ['POST','PUT','PATCH']
method2.forEach(element => {
    http[element.toLowerCase()]=function(url,data,config){
        if(config===null || config===undefined) config = {};
        config['url']=url;
        config['method']=element;
        config['body'] = data;
        // console.log('config',config)
        return http(config);
    }
});

const postFile =async (url,data)=>{
    const formData = new FormData();
    // 遍历文件数组并将每个文件添加到FormData中  
    formData.append('token', data.token);  
    if(data.files){
        for (let i = 0; i < data.files.length; i++) {  
            formData.append('files', data.files[i]);  
        } 
    }else if(data.file){
        formData.append('file', data.file);
    }
    return fetch(url,{method:"POST",body:formData}).then(response=>{
        let {status,statusText} = response;
        // console.log('response',(/^(2|3)\d{2}$/.test(status)),status)
        if(/^(2|3)\d{2}$/.test(status)){
            let result = response.json();
            return result;
        }
        return Promise.reject({
            status:1,
            msg:'请求出错'
        });

    }).catch(err=>{
        return Promise.reject(err)
    });
}




export default http;
export{http,postFile}