import { Button, Form, Input, Loading } from "antd-mobile"
import VerificationCode from "../../compoents/verification/verificationCode";
import styles from './login.module.less'
import { useNavigate } from "react-router-dom";

import routePath from "../../routers/config";
import { login } from "../../api/auth/auth";
import { setLocal } from "../../utils/store";
import { tokenName } from "../../config";
import { useState } from "react";

const Login = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [loadingVisible, setLoadingVisible] = useState(false)
    const onSubmit = async () => {
        const values = form.getFieldsValue();
        let r = /1[0-9]\d{9}$/
        if (!r.test(values.phone)) {
            form.setFields([{ 'name': "phone", "errors": ['请输入正确手机号'] }])
            return
        }
        if (values.code && values.code.length != 6) {
            form.setFields([{ 'name': "code", "errors": ['验证码为6位'] }])
            return
        }
        if (loadingVisible) {
            return
        }
        setLoadingVisible(true)
        let result = await login(values.phone, values.code)
        setLoadingVisible(false)
        if (result.status === 0) {
            setLocal(tokenName, result.data.token)
            navigate(routePath.home, { replace: true })
        }

    }

    function to(path) {
        navigate(path);
    }
    return <div >

        <div className={styles.lable}><div>登录</div></div>
        <Form form={form}
            requiredMarkStyle="none"
            ayout="vertical"
            mode='card'

            footer={<Button block color='primary' onClick={onSubmit} size='large'> 登录{loadingVisible?<Loading/>:<></>}</Button>}>
            <Form.Header>未注册的手机号验证通过后将自动注册</Form.Header>
            <Form.Item name='phone'
                required
                label='手机号'
                rules={[
                    { min: 11 }
                ]}

            >
                <Input placeholder='请输入手机号' />
            </Form.Item >
            <Form.Item name='code'

                rules={[
                    { min: 6 }
                ]}
                label='短信验证码'
                extra={<VerificationCode />}>
                <Input placeholder='请输入验证码' />
            </Form.Item>
        </Form>
        <div className={styles.other}>
            <span onClick={() => { to(routePath.loginPwd) }}>密码登录</span>
        </div>
    </div>
}

export default Login;