import { makeAutoObservable,runInAction, toJS } from "mobx";
import { getUserDataByUserId } from "../../api/user/user";
import { getUserBlogData } from "../../api/mblog/mblog";
import head from '../../assets/img/headimg.png'
import backgroundImg from "../../assets/img/background.png"

class UserStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    userData = {};
    pageNum=1;
    pageSize = 10;
    userMBlogData=[];
    userid=-1;

    updatePageNume(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
        
    }


    async getUserData(userid){
        if(Object.keys(this.userData>0)&&userid===this.userid){
            return
        }
        let userData={}
            userData.nickname= "";
            userData.accountName= "";
            userData.headImg= head;
            userData.backgroundImg= backgroundImg;
            userData.userid= 0;
            userData.sex= 0;
            userData.birthday= "未知";
            userData.remark= "";
            userData.zanNum= 0;
            userData.fansNum= 0;
            userData.guanZhuNum= 0;
            userData.phone= "";
            userData.tag= "";
            userData.following=false;
        let result = await getUserDataByUserId(userid);
        this.userid=userid;
        if(result.status==0){
            runInAction(() => {
                let userData={}
                userData.nickname= result.data.nickname?result.data.nickname:"";
                userData.accountName= result.data.account_name?result.data.account_name:"";
                userData.headImg= result.data.headImg?result.data.headImg:head;
                userData.backgroundImg= result.data.backgroundimg?result.data.backgroundimg:backgroundImg;
                userData.userid= result.data.userid?result.data.userid:0;
                userData.sex= result.data.sex?result.data.sex:0;
                userData.birthday= result.data.birthday?result.data.birthday:"";
                userData.remark= result.data.remark?result.data.remark:"";
                userData.zanNum= result.data.zan_num?result.data.zan_num:0;
                userData.fansNum= result.data.fans_num?result.data.fans_num:0;
                userData.guanZhuNum= result.data.follow_num?result.data.follow_num:0;
                userData.phone= result.data.phone?result.data.phone:"";
                userData.tag= result.data.tag?result.data.tag:"";
                userData.following=result.data.following;
                this.userData = userData;
        })
        
        }else{
            this.userData = userData;
        }
        return {status:result.status,msg:result.msg}
        
    }

    clearData(userid){
        if(userid!==this.userid){
            this.userData = {}
            this.userMBlogData=[];
            this.pageNum=1;
        }
        
    }

    updateUserData(key,value){
        let userData = {...this.userData}
        userData[key]=value;
        this.userData = userData;
    }

    async getUserMicroBlogData(userid){
        let result = await getUserBlogData(userid,this.pageNum,this.pageSize);
        runInAction(()=>{
            if(result.status===0){
                if(this.pageNum===1&&userid!=this.userid){
                    this.userMBlogData = result.data
                }else{
                    
                    this.userMBlogData = this.userMBlogData.concat(result.data)
                }
            }
        })
        
        return {status:result.status,msg:result.msg,hasMore:result.data.length=== this.pageSize}
        
    }
}

export default UserStore;