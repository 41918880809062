import { getLocal } from "../utils/store";
import { toWhere } from "../routers/config";
import { makeAutoObservable } from "mobx";

class SelectStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    select = getLocal("select")?getLocal("select"):"/home";
    updateSelect(path){
        this.select = path;
    }
    getSelect(){
        return toWhere()
    }
}

export default SelectStore;