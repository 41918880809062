import "../../assets/iconFonts/iconfont.css"


//首页图标
const HomeIcon = (props)=>{
    return (
        <span className="iconfont icon-icon-shouyexuanzhong" style={{...props}} ></span>
    );
}
//动态页图标
const DontTaiIcon = (props)=>{
    return (
        <span className="iconfont icon-dongtai" ></span>
    );
}
//添加页图标
const UploadIcon = (props)=>{
    return (
        <span className="iconfont icon-tianjia" style={{...props}} ></span>
    );
}
//返回图标
const BackIcon = (props)=>{
    return (
        <span className="iconfont icon-fanhui" style={{...props}} ></span>
    );
}

//消息页图标
const MsgIcon = (props)=>{
    return (
        <span className="iconfont icon-xiaoxi" style={{...props}} ></span>
    );
}
//我的页图标
const MineIcon = (props)=>{
    return (
        <span className="iconfont icon-wode-copy" style={{...props}} ></span>
    );
}
//选择页图标
const SelectionIcon = (props)=>{
    return (
        <span className="iconfont icon-gongchengdongtai" style={{...props}}></span>
    );
}
//分享
const ShareOutLineIcon = (props)=>{
    return (
        <span className="iconfont icon-fenxiang2" style={{...props}}></span>
    );
}

//空心♥
const HeartOutLineIcon = (props)=>{
    return <span className="iconfont icon-kongaixin" style={{...props}}></span>
}

//爱心
const HeartIcon = (props)=>{
    return <span className="iconfont icon-aixin" style={{...props}}></span>
}
//comment
const CommentIcon = (props)=>{
    return <span className="iconfont icon-pinglun" style={{...props}}></span>
}
const CommentSmileIcon = (props)=>{
    return <span className="iconfont icon-pinglun-tianchong" style={{...props}}></span>
}
const ShareIcon = (props)=>{
    return <span className="iconfont icon-fenxiangzhuanfa" style={{...props}}></span>
}



//线性评论
const CommentOutLineIcon = (props)=>{
    return <span className="iconfont icon-pinglun1" style={{...props}}></span>
}
//闭眼
const EyeInvisibleOutline = (props)=>{
    return <span className="iconfont icon-biyanjing-xianxing3-0" style={{...props}}></span>
}
//眼睛
const Eye = (props)=>{
    return <span className="iconfont icon-yanjing" style={{...props}}></span>
}

//加号图标
const AddIcon = (props)=>{
    return (
        <span className="iconfont icon-jiahao" style={{...props}} ></span>
    );
}

//删除
const DeleteIcon = (props)=>{
    return (
        <span className="iconfont icon-shanchu1" style={{...props}} ></span>
    );
}
//更多
const MoreIcon = (props)=>{
    return (
        <span className="iconfont icon-more" style={{...props}} ></span>
    );
}
//更多
const More1Icon = (props)=>{
    return (
        <span className="iconfont icon-more1" style={{...props}} ></span>
    );
}

//播放

const PlayIcon = (props)=>{
    return (
        <span className="iconfont icon-bofang1" style={{...props}} ></span>
    );
}
////////////////////////////////////////SVG////////////////////////////////////////
//video
const VideoIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-shipin" ></use>
    </svg>
}
//综合
const AllIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-zongheguanli"></use>
    </svg>
}
//mblog
const MBlogIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-wenzhang" ></use>
    </svg>
}
//短视频
const ShortVideoIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-duanshipin"></use>
    </svg>
}
//文章
const ArticleIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-wenzhang1"></use>
    </svg>
}
//漫画
const ManhuaIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-manhua"></use>
    </svg>
}
//收到的评论
const ReceivedCommentIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-pinglun2"></use>
    </svg>
}
//收到的点赞
const ReceivedLikeIcon = (props)=>{
    return <svg className="icon" aria-hidden="true" {...props}>
        <use xlinkHref="#icon-xihuan"></use>
    </svg>
}

export  {
    HomeIcon,
    BackIcon,
    DontTaiIcon,
    UploadIcon,
    MsgIcon,
    MineIcon,
    SelectionIcon,
    ShareOutLineIcon,
    HeartIcon,
    CommentIcon,CommentSmileIcon,
    ShareIcon,
    HeartOutLineIcon,
    CommentOutLineIcon,
    VideoIcon,
    AllIcon,
    MBlogIcon,
    ShortVideoIcon,
    ArticleIcon,
    ManhuaIcon,
    Eye,
    EyeInvisibleOutline,
    AddIcon,
    DeleteIcon,
    ReceivedCommentIcon,
    More1Icon,
    MoreIcon,
    ReceivedLikeIcon,
    PlayIcon
    
}