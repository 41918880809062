import CommentStore from "./commentStore";
import DongTaiStore from "./dongtaiStore";
import MBlogStore from "./mBlogStore";
import MineStore from "./mine/mineStore";
import MsgStore from "./msgStore";
import SelectStore from "./selectStore";
import UnionStore from "./unionStore";
import UserStore from "./user/userStore";
import ShortVideoUploadStore from "./shortVideoUploadStore";
import ShortVideoStore from "./shortVideoStore";
import MineShortVideoStore from "./mine/shortvStore";
import MineVideoStore from "./mine/videoStore";
import UserShortVideoStore from "./user/shortvStore";
import UserVideoStore from "./user/videoStore";
import VideoStore from "./video/videoStore";
import VideoUploadStore from "./video/videoUploadStore";

class Store{
    constructor() {
        this.MBlog = new MBlogStore(this);
        this.Mine = new MineStore(this);
        this.User = new UserStore(this);
        this.Select = new SelectStore(this);
        this.DongTai = new DongTaiStore(this);
        this.Msg = new MsgStore(this);
        this.CommentStore = new CommentStore(this)
        this.UnionStore = new UnionStore(this)
        this.ShortVideoUploadStore = new ShortVideoUploadStore(this)
        this.ShortVideoStore = new ShortVideoStore(this)
        this.MineShortVideoStore = new MineShortVideoStore(this)
        this.MineVideoStore = new MineVideoStore(this)
        this.UserShortVideoStore = new UserShortVideoStore(this)
        this.UserVideoStore = new UserVideoStore(this)
        this.VideoUploadStore = new VideoUploadStore(this)
        this.VideoStore = new VideoStore(this)
    }
}

export default new Store();