import axios from "axios";
import { getLocal } from "./store";
import { tokenName } from "../config";

const service = axios.create({
    timeout: 10000*6,
});

service.interceptors.request.use(
    config => {
        let token = getLocal(tokenName)
        // 请求拦截
        config.headers['Authorization'] = token;
        return config;
    },
    error => {
        // 请求错误处理
        console.log(error);
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        // 响应拦截
        return response;
    },
    error => {
        // 响应错误处理
        console.log(error);
        return Promise.reject(error);
    }
);

// get请求
function get(url, config) {
    return new Promise((resolve,reject)=>{
        service.get(url, config).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data);
        });
    })
}

// post请求
async function  post(url,data, config) {
    return new Promise((resolve,reject)=>{
        service.post(url,data, config).then(res => {
            console.log("response",res)
            resolve(res.data);
        }).catch(err => {
            reject(err.data);
        });
    })
}

// put请求

function put(url,data, config) {
    return new Promise((resolve,reject)=>{
        service.put(url,data, config).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data);
        });
    })
}

// delete请求
function del(url, config) {
    return new Promise((resolve,reject)=>{
        service.delete(url, config).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data);
        });
    })
}


// 文件上传
const postFile =async (url,data,config)=>{
    if(!config){
        config = {}
    }
    const formData = new FormData();
    for(let key in data){
        formData.append(key,data[key]);
    }
    config = Object.assign({
        headers: {
            'Content-Type': 'application/octet-stream'
        }
    },config);
    return post(url,formData,config).then(response=>{
        return response

    }).catch(err=>{
        return Promise.reject(err)
    });
}

/*
    上传文件
    @param url 上传地址
    @param data 上传数据
    @param config 上传配置
    @return Promise
*/
const uploadFile =async (url,data,config)=>{
    if(!config){
        config = {}
    }
    config = Object.assign({
        headers: {
            'Content-Type': 'application/octet-stream'
        }
    },config);
    return post(url,data,config).then(response=>{
        return response

    }).catch(err=>{
        return Promise.reject(err)
    });
}

export {
    get,
    post,
    put,
    del,
    postFile,
    uploadFile
};
