const IImage = (props)=>{
    
    let params = {}
    if(props.className){
        params.className=props.className
    }
    let src = props.src?props.src:""
    params.src=src
    if(props.lazy){
        params.loading='lazy'
    }
    if(props.alt){
        params.alt=props.alt
    }
    // if(props.width){
    //     params.width=props.width
    // }
    params.width=props.width?props.width:'100%'
    params.height=props.height?props.height:'100%'
    if(props.onClick){
        params.onClick = props.onClick
    }
    if(props.style){
        params.style=props.style
    }

    return <img {...params}></img>

}

export {
    IImage
}