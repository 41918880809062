import { makeAutoObservable, runInAction } from "mobx";
import { delMblog, getMBlogData } from "../api/mblog/mblog";
import { getBannerAd } from "../api/ad/ad";

class UnionStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    //内容数据
    unionData =[];
    bannerData = []
    pageNum =1;
    pageSize = 10;
    unionDataMap={}
    updatePageNume(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
        
    }
     //更新去重map
    updateUnionDataMap(id){
        if(this.unionDataMap[id]){
            return false
        }else{
            this.unionDataMap[id] = 1
            return true
        }
    }
    async getBannerAdData(){
        let result = await getBannerAd()
        if(result.status===0){
            runInAction(()=>{
                let bannerData = []

                for (let info of result.data){
                    let data = {
                        faceUrl : info.face_url,
                        toUrl:info.to_url,
                        title:info.title
                    }
                    bannerData.push(data)
                }
                this.bannerData = bannerData;

            })
        }
    }

    async getData() {
        let result = await getMBlogData(this.pageNum,this.pageSize)
        let hasMore = true;
        if(result.status===0){
            runInAction(()=>{
                if(this.pageNum===1){
                    this.unionData = result.data;
                    this.unionDataMap={}
                    if(Array.isArray(result.data)){
                        result.data.map((item)=>{
                            this.updateUnionDataMap(item.id)
                        })
                    }
                }else{
                    if(Array.isArray(result.data)){
                        let newData=[] 
                        result.data.map((item)=>{
                            let ok = this.updateUnionDataMap(item.id)
                            if(ok){
                                newData.push(item)
                            }
                        })
                        this.unionData = this.unionData.concat(newData)
                    }
                }
                
                
            })
        }
        
        if(Array.isArray(result.data)&&result.data.length<this.pageNum){
            hasMore = false
        }
        let status = 0
        if(result.status==undefined){
            status = 1
        }
        
        return {status:result.status,msg:result.msg,hasMore}
    }

    //删除
    async deleteItem(id,userid){
        let result = await delMblog(id,userid)
        if(result.status===0){
            runInAction(()=>{
                this.unionData = this.unionData.filter((item)=>{
                    return item.id!=id;
                })
            })
        }
        return result
        
    }

    //更新评论数
    updateCommentNum(blogId,num){
        if(!num){
            num = 1
        }
        for (let index = 0; index < this.unionData.length; index++) {
            const element = this.unionData[index];
            if(element.id===blogId){
                element.comment_num+=num;
                if(element.comment_num<0){
                    element.comment_num = 0
                }
            }
        }
    }
    //更新点赞数数
    updateLikeNum(blogId,num){
        if(!num){
            num = 1
        }
        for (let index = 0; index < this.unionData.length; index++) {
            const element = this.unionData[index];
            if(element.id===blogId){
                element.like_num+=num;
                if(element.like_num<0){
                    element.like_num = 0
                }
            }
        }
    }

}
export default UnionStore;