import { List,NavBar,Badge } from "antd-mobile";
import { ReceivedCommentIcon,ReceivedLikeIcon } from "../../compoents/IconFonts/iconfont";
import { useNavigate } from "react-router-dom";
// import styles from './message.module.less'
import routePath from "../../routers/config";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";

const Message = (props)=>{
    let navigate = useNavigate();
    let {Msg} = props;
    let {lastLike,lastComment,unreadLikeNum,unreadCommentNum} = Msg
    let [info,setInfo] = useState({lastLike,lastComment,unreadLikeNum,unreadCommentNum})
    Msg.getUnreadNum()
    useEffect(()=>{
        setInfo((preState)=>{
            return {lastLike,lastComment,unreadLikeNum,unreadCommentNum}
        })
    },[lastLike,lastComment])
    useEffect(()=>{
        Msg.getLastComment()
        Msg.getLastLike()
    },[unreadLikeNum,unreadCommentNum])
    let likeInfo = "没有人点赞"
    if(info.lastLike&&info.lastLike!="undefined"){
        likeInfo = info.lastLike.author.name+"赞了你"
    }
    let commentInfo = "没有人评论"
    if(info.lastComment&&info.lastComment!="undefined"){
        commentInfo = info.lastComment.author.name+"评论了你"
    }
    return (
        <>
            <NavBar back={null} >消息</NavBar>
            <List mode="card">
                <List.Item 
                    prefix={<ReceivedLikeIcon fontSize='0.8rem'/>} 
                    description={likeInfo} clickable 
                    extra={<Badge content={info.unreadLikeNum==0?null:info.unreadLikeNum}></Badge>}
                    onClick={()=>{
                        navigate(routePath.receivedLike)
                    }}
                    >
                    收到的点赞
                </List.Item>
                <List.Item 
                    prefix={<ReceivedCommentIcon 
                    fontSize='0.8rem'/>} 
                    description={commentInfo} clickable 
                    extra={<Badge content={info.unreadCommentNum==0?null:info.unreadCommentNum}></Badge>}
                    onClick={()=>{
                        navigate(routePath.receivedComment)
                    }}
                    >
                    收到的评论
                </List.Item>
            </List>
        </>
    );
}
export default inject("Msg")(observer(Message));