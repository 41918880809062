
const uploadBaseURL = "http://192.168.10.100:82";
// const uploadBaseURL = "http://127.0.0.1:82"
const baseURL = "http://47.92.239.96:82";
// const baseURL = "http://127.0.0.1:82";

//http配置
const httpConfig ={
    
    timeout : 10000,
    
}

//用户账号
const authHttpConfig = {
    loginPhone : baseURL+"/login",///手机号登录
    loginPwd : baseURL+"/login/pwd",///密码登录
    register : baseURL+"/register",///注册
    refreshtoken : baseURL+"/refreshtoken",///刷新token
}

//用户相关
let userName = '/user';
const userHttpConfig={
    userinfo : baseURL+"/user",///获取用户自己的信息
    otherUser:baseURL+"/user/info",//获取其他用户的主页
    updateHeadimg : baseURL+"/user/headimg/update",///更新用户的头像
    updateBackgroundimg : baseURL+"/user/backgroundimg/update",///更新用户主页背景图
    updateNickname : baseURL+"/user/nickname/update",///更新用户的头像
    updatesex : baseURL+"/user/sex/update",///更新用户的性别
    updateBirthday : baseURL+"/user/birthday/update",///更新用户的出生日期
    updateRemark : baseURL+"/user/remark/update",///更新用户的个性签名
    accountName : baseURL+"/user/accountname/update",///更新用户的个性签名
    updatePhone : baseURL+"/user/phone/update",///更新用户的手机号
    follow : baseURL+"/user/follow",///关注
    unfollow : baseURL+"/user/unfollow",///取消关注
}

const mblogName="mblog";
const microBlogHttpConfig={
    
    data :`${baseURL}/${mblogName}/get`,
    mineData :`${baseURL}/${mblogName}/mine`,//个人数据
    userData :`${baseURL}/${mblogName}/user`,//个人数据
    create :`${baseURL}/${mblogName}/create`,
    detail :`${baseURL}/${mblogName}/detail`,
    delete : `${baseURL}/${mblogName}/del`,
    comment :`${baseURL}/${mblogName}/comment/list`,//评论数据
    createComment :`${baseURL}/${mblogName}/comment/create`,//发表评论数据
    commentReply :`${baseURL}/${mblogName}/comment/reply/list`,//回复评论数据
    like :`${baseURL}/${mblogName}/like`,//点赞
    unlike :`${baseURL}/${mblogName}/unlike`,//取消点赞
    mBlogDetail : `${baseURL}/${mblogName}/detail`,//microblog详情
    uploadImage : `${baseURL}/${mblogName}/image/upload`,//上传图片
    deleteImage : `${baseURL}/${mblogName}/image/delete`,//删除图片

    report:`${baseURL}/${mblogName}/report`,//举报

}

//短视频
const shortVideoName = "shortv";
const shortVideoHttpConfig={
    delete : `${baseURL}/${shortVideoName}/del`,
    comment :`${baseURL}/${shortVideoName}/comment/list`,//评论数据
    createComment :`${baseURL}/${shortVideoName}/comment/create`,//发表评论数据
    commentReply :`${baseURL}/${shortVideoName}/comment/reply/list`,//回复评论数据
    like :`${baseURL}/${shortVideoName}/like`,//点赞
    unlike :`${baseURL}/${shortVideoName}/unlike`,//取消点赞

    report:`${baseURL}/${shortVideoName}/report`,//举报

    getVideoInfo:`${baseURL}/${shortVideoName}`,
    getVideoList:`${baseURL}/${shortVideoName}/list`,//视频列表
    getUserVideoList:`${baseURL}/${shortVideoName}/user/list`,//用户的视频列表
    
}
//短视频上传
const shortVideoUploadHttpConfig={
    upload :`${uploadBaseURL}/${shortVideoName}/v/upload`,
    deleteVideo :`${uploadBaseURL}/${shortVideoName}/v/delete`,
    deleteInfo :`${uploadBaseURL}/${shortVideoName}/v/delete`,
    createInfo:`${uploadBaseURL}/${shortVideoName}/v/create`,
}
//长视频
const videoName = "video";
const videoHttpConfig={
    delete : `${baseURL}/${videoName}/del`,
    comment :`${baseURL}/${videoName}/comment/list`,//评论数据
    createComment :`${baseURL}/${videoName}/comment/create`,//发表评论数据
    commentReply :`${baseURL}/${videoName}/comment/reply/list`,//回复评论数据
    like :`${baseURL}/${videoName}/like`,//点赞
    unlike :`${baseURL}/${videoName}/unlike`,//取消点赞

    report:`${baseURL}/${videoName}/report`,//举报

    getVideoInfo:`${baseURL}/${videoName}`,
    getVideoList:`${baseURL}/${videoName}/list`,//视频列表
    getUserVideoList:`${baseURL}/${videoName}/user/list`,//用户的视频列表
    
}
//短视频上传
const videoUploadHttpConfig={
    upload :`${uploadBaseURL}/${videoName}/v/upload`,
    deleteVideo :`${uploadBaseURL}/${videoName}/v/delete`,
    deleteInfo :`${uploadBaseURL}/${videoName}/v/delete`,
    createInfo:`${uploadBaseURL}/${videoName}/v/create`,
}

const messageName ="/msgfeed";
const messageHttpConfig={
    
    unreadNum : `${baseURL}${messageName}/unreadnum`, //未读的信息数
    receivedlike : `${baseURL}${messageName}/receivedlike`, //获取收到的点赞
    receivedcomment : `${baseURL}${messageName}/receivedcomment`, //未读的信息数
}


///动态请求的网络路径
let dongTaiPath = "/dongtai";
const dongtaiHttpConfig={
    
    updateNumPath : `${baseURL}${dongTaiPath}/updatenum`, //用户关注人的新发布的内容数量
    all : `${baseURL}${dongTaiPath}/all`//获取所有
}

//广告
const adPath = "/ad"
const adHttpConfig={
    banner : `${baseURL}${adPath}/banner`
}

export {
    httpConfig,
    authHttpConfig,
    userHttpConfig,
    microBlogHttpConfig,
    messageHttpConfig,
    dongtaiHttpConfig,
    shortVideoHttpConfig,
    shortVideoUploadHttpConfig,
    videoHttpConfig,
    videoUploadHttpConfig,
    adHttpConfig

}