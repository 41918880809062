import { makeAutoObservable, runInAction } from "mobx";
import { getReceivedLike,getReceivedComment,getUnreadNum } from "../api/message/msg";
import { getLocal, setLocal } from "../utils/store";
import { lastComment, lastLike ,unreadCommentNum,unreadLikeNum} from "../config";
//之前存储的最新的评论
let storeComment = getLocal(lastComment)
if(storeComment&&storeComment!="undefined"){
    storeComment = JSON.parse(storeComment)
}
//存储的最新的获赞
let storeLike = getLocal(lastLike)
if(storeLike&&storeLike!="undefined"){
    storeLike = JSON.parse(storeLike)
}
//获赞未读数目
let storeUnreadLikeNum = getLocal(unreadLikeNum)
if(!storeUnreadLikeNum){
    storeUnreadLikeNum = 0
}
//获赞未读数目
let storeUnreadCommentNum = getLocal(unreadCommentNum)
if(!storeUnreadCommentNum){
    storeUnreadCommentNum = 0
}
class MsgStore{
    constructor(root){
        this.root = root;
        makeAutoObservable(this)
    }
    receivedLikeData = []//收到的点赞数据
    receivedCommentData = []//收到的评论数据
    receivedLikePageNum=1 //收到的点赞数据现在是到了第几页
    receivedCommentPageNum=1 //收到的评论数据现在是到了第几页
    unreadLikeNum = storeUnreadLikeNum; //未读的收到的点赞数
    unreadCommentNum = storeUnreadCommentNum;//未读的收到的评论数
    pageSize = 15 //一页数据有几条
    lastLike = storeLike;//最新一条点赞
    lastComment = storeComment;//最新一条评论
    timerId = null;//定时器
    receivedLikeMap={}
    receivedCommentMap={}
    
    //更新去重map
    updateReceivedLikeMap(id){
        if(this.receivedLikeMap[id]){
            return false
        }else{
            this.receivedLikeMap[id] = 1
            return true
        }
    }
    updateReceivedCommentMap(id){
        if(this.receivedCommentMap[id]){
            return false
        }else{
            this.receivedCommentMap[id] = 1
            return true
        }
    }
    //未读消息数
    updateReceivedLikePageNum(num){
        if(!num){
            this.receivedLikePageNum+=1;
        }else{
            this.receivedLikePageNum = num;
        }
        
    }
    updateReceivedCommentPageNum(num){
        if(!num){
            this.receivedCommentPageNum+=1;
        }else{
            this.receivedCommentPageNum = num;
        }
    }
    //更新未读的消息数
    updateUnreadLikeNum(num){
        this.unreadLikeNum = num
        setLocal(unreadLikeNum,num)

    }
    updateUnreadCommentNum(num){
        this.unreadCommentNum = num
        setLocal(unreadCommentNum,num)
    }
    //获得未读数
    async getUnreadNum(){
        if(this.timerId){
            return
        }else{
            this.timerId = setInterval(async ()=>{
                let result = await getUnreadNum()
                if(result.status===0){
                    runInAction(()=>{
                        this.updateUnreadCommentNum(result.data.unread_comment_num)
                        this.updateUnreadLikeNum(result.data.unread_like_num)
                    })
                }
            },20000)
        }
    }
    //获得最新的一条评论
    async getLastComment(){
        let result = await getReceivedComment(1,1)
        if(result.status===0){
            runInAction(()=>{
                this.lastComment = result.data.items[0]
                setLocal(lastComment,this.lastComment)
            })
        }
        return {status:result.status,msg:result.msg}
    }
    //获得最新的一条点赞
    async getLastLike(){
        let result = await getReceivedLike(1,1)
        if(result.status===0){
            runInAction(()=>{
                this.lastLike = result.data.items[0]
                setLocal(lastLike,this.lastLike)
            })
        }
        return {status:result.status,msg:result.msg}
    }

    //获得收到的点赞数据
    async getReceivedLikeData(){
        let result = await getReceivedLike(this.receivedLikePageNum,this.pageSize)
        let hasMore = true;
        if(result.status===0){
            runInAction(()=>{
                if(this.receivedLikePageNum===1){
                    this.receivedLikeData = result.data.items;
                    this.receivedLikeMap = {}
                    if(Array.isArray(result.data.items)){
                        result.data.items.map((item)=>{
                            this.updateReceivedLikeMap(item.dynamic.id)
                        })
                    }
                    // console.log("this.receivedLikeMap",JSON.stringify(this.receivedLikeMap))
                }else{
                    let newData = []
                    if(Array.isArray(result.data.items)){
                        result.data.items.map((item)=>{
                            let ok = this.updateReceivedLikeMap(item.dynamic.id)
                            if(ok){
                                newData.push(item)
                            }
                        })
                    }
                    this.receivedLikeData = this.receivedLikeData.concat(newData)
                }
                if(result.data.items.length<this.pageSize){
                    this.receivedLikePageNum+=1
                }
            })
            if(result.data.items.length<this.pageSize){
                hasMore = false;
            }
        }
        
        return {status:result.status,msg:result.msg,hasMore}
        
    }
    //获得收到的评论数据
    async getReceivedCommentData(){
        let result = await getReceivedComment(this.receivedLikePageNum,this.pageSize)
        let hasMore = true;
        if(result.status===0){
            runInAction(()=>{
                if(this.receivedCommentPageNum===1){
                    this.receivedCommentData = result.data.items;
                    this.receivedCommentMap = {}
                    if(Array.isArray(result.data.items)){
                        result.data.items.map((item)=>{
                            this.updateReceivedCommentMap(item.dynamic.id)
                        })
                    }
                }else{
                    let newData = []
                    if(Array.isArray(result.data.items)){
                        result.data.items.map((item)=>{
                            let ok = this.updateReceivedCommentMap(item.dynamic.id)
                            if(ok){
                                newData.push(item)
                            }
                        })
                    }
                    this.receivedCommentData = this.receivedCommentData.concat(newData)
                }
                if(result.data.items.length<this.pageSize){
                    this.receivedLikePageNum+=1
                }
                
                
            })
            if(result.data.items.length<this.pageSize){
                hasMore = false;
            }
        }
        return {status:result.status,msg:result.msg,hasMore}
        

    }
}

export default MsgStore;