import { useState, useEffect } from "react";

import styles from './verificationCode.module.less'


const VerificationCode = () => {
    const [count, setCount] = useState(60);
    const [timerId, setTimerId] = useState(null);

    useEffect(() => {
    if (count===0&&timerId) {
        clearInterval(timerId);
    }

    return () => {
        clearInterval(timerId)
    };
    }, [timerId]);
    useEffect(() => {
    if (count<=0) {
        clearInterval(timerId);
        setTimerId(null)
    }
    }, [count]);

    function handleClick(){
        setCount((state)=>{
            return 60;
        });
        
        setTimerId(()=>{
            return setInterval(() => {
                setCount((state)=>{
                    return state-1
                });
                console.log(count)
            }, 1000)
        });
    }

    return (
    <div>
        {timerId?<span>{count+" 秒"}</span>:<span className={styles.code} onClick={()=>{handleClick()}}>发送验证码</span>}
        
    </div>
    );
};

export default VerificationCode;