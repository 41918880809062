import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import routesInfo from "./routes";

const Element = function Element(props) {
    let {component:Component,meta} = props;
    let title = meta&&meta.title? meta.title:"";
    document.title = title;
    return <Component/>;
    
}

const createRoutes = (props)=>{
    let {routes}=props;
    return <>
        {routes.map(((item,index)=>{
            let {path,children} = item;
            return <Route key={index} path={path} element={<Element {...item}/>} >
                {Array.isArray(children)&&children.length>0 ? createRoutes({routes:children}) : null}
            </Route>
        }))}
    </>
        
}


function RouterView(){
    return <Suspense >
            <Routes>
            {createRoutes({routes:routesInfo})}
            </Routes>
            
    </Suspense>
}

export default RouterView;