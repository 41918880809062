import {  useState } from 'react';
import styles from './imageUpload.module.less'
import { AddIcon, DeleteIcon } from '../IconFonts/iconfont';
import { IImage } from '../../UI/Image/image';


import { generateUnionId } from '../../utils/commonUtils';
const ImageUploader=(props)=>{
    const maxImageNum = 9
    let fileList = props.fileList?props.fileList:[]
    // let [fileList,setFileLIst] = useState([])
    
    function handleDelete(imgId){
        props.delete(imgId)
        let newFileList = fileList.filter((item)=>item.id!=imgId)
        props.onChange(newFileList)
    }

    return <div className={styles.image_upload_space}>
        <ImageShow imgList={fileList} type={props.showType} parentCall={(value)=>{handleDelete(value)}} />
        {fileList.length>=maxImageNum ? <></>:<div className={styles.image_upload_button_wrap}>
        <div className={styles.image_upload_icon}>
            <AddIcon fontSize='0.7rem' />
        </div>
        <input className={styles.image_upload_input} type='file' aria-hidden='true' accept='image/*' multiple onChange={async (event)=>{
            // console.log(event.target.files)
            let img_list = []
            if(event.target.files.length+fileList.length>maxImageNum){
                let newFiles = event.target.files.slice(0,maxImageNum-fileList.length)
                img_list= await props.upload(newFiles)
            }else if(event.target.files.length+fileList.length<=maxImageNum){
                img_list= await props.upload(event.target.files)
            }
            let newList = fileList.concat(img_list)
            event.target.value=''
            props.onChange(newList)
            // setFileLIst((list)=>{
                
            //     let newList = [...list]
                
                
            //     newList.push({id:generateUnionId(),url:'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=60'})
            //     return newList
            // })

        }}/>
    </div>}
        
    </div>
}

////////////////////////////////////

const ImageShow = (props)=>{
    const type = props.type
    const imgList=props.imgList;
    console.log('imgListimgList',imgList)
    //图片链接
    let srcList=[]
    imgList.map((info)=>{
        srcList.push(info.url)
        return info
    })
    console.log('srcListsrcList',srcList)
    const imgListLen = imgList.length;
    if(!type||type==='grid'){
        let className=styles.img_grid;
        switch (true) {
            case imgListLen===1:
                className = styles.mg_grid_one
                return <div style={{position:'relative',width:"fit-content"}}>
                    <IImage className={styles.img_grid_one} src={imgList[0].url}  ></IImage>
                {/* <span style={{position:'absolute',right:0, top:0,color:'red'}}><DeleteIcon /></span> */}
                <DeleteImg imgId={imgList[0].id} onClick={()=>{
                    props.parentCall(imgList[0].id);
                }}/>
                </div>
            case imgListLen===2:
                className = styles.img_grid_two;
                break;
            case imgListLen===4:
                className = styles.img_grid_four;
                break;
        
            default:
                className = styles.img_grid;
                break;
        }
        return <div className={className} >
            {imgList.map((imgInfo,index)=>{            
                return <div key={index} style={{position:'relative'}}>
                    <IImage src={imgInfo.url} key={imgList[0].id}   lazy={true}   style={{borderRadius: '8px',aspectRatio: 1}} ></IImage>
                {/* <span style={{position:'absolute',right:0, top:0,color:'red'}}><DeleteIcon /></span> */}
                <DeleteImg imgId={imgList[0].id} onClick={()=>{
                    props.parentCall(imgList[0].id);
                }}/>
                
                </div>
            })}
        </div>
    }else{
        if(imgListLen<=1){
            if(imgListLen===0){
                return <></>
            }else{
                return  <div  style={{position:'relative'}}>
                    <IImage className={styles.img_slide_one} src={imgList[0].url}   
                        height={props.height} 
                        width={props.width}  
                        lazy={true} 

                        style={{borderRadius: '8px'}
                        
                    } ></IImage>
                    <DeleteImg imgId={imgList[0].id} onClick={()=>{
                    props.parentCall(imgList[0].id);
                }}/>
                </div>
                
            }
            
        }
        let className=styles.img_slide;
        return <div className={className}>
        {imgList.map((imgInfo,index)=>{
            return <div className={styles.img_prew} key={index} style={{position:'relative',minWidth:'80%'}}>
                <IImage src={imgInfo.url} key={index}  width={'100%'} ></IImage>
                <DeleteImg imgId={imgInfo.id} onClick={()=>{
                    props.parentCall(imgInfo.id);
                }}/>
            </div>
            
        })}
    </div>
    }
    
}

//图片删除
const DeleteImg = (props)=>{
    return <div onClick={props.onClick}
    style={{
        position:'absolute' ,
        right:0, 
        top:0,
        color:'red',
        borderTopRightRadius: '8px',
        overflow:'hidden'
    }}><DeleteIcon fontSize='0.5rem'  borderTopRightRadius= '8px'/></div>
}
export default ImageUploader;