import { makeAutoObservable, runInAction } from "mobx";
import { getDongTaiData, getUpdateNum } from "../api/dongtai/dongtai";
import { setLocal } from "../utils/store";
import { baselineName } from "../config";
import { delMblog } from "../api/mblog/mblog";


class DongTaiStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    pageNum = 1;
    pageSize = 20;
    dongtaiData = [];
    updateNum = 0;
    dongtaiMap ={}
    updatePageNum(num){
        if(num){
            this.pageNum = num;
        }else{
            this.pageNum+=1;
        }
    }
    //更新去重map
    updateDongtaiMap(id){
        if(this.dongtaiMap[id]){
            return false
        }else{
            this.dongtaiMap[id] = 1
            return true
        }
    }

    //获得未读数
    async getUpdateNum(){
        if(this.timerId){
            return
        }else{
            this.timerId = setInterval(async ()=>{
                let result = await getUpdateNum()
                if(result.status===0){
                    runInAction(()=>{
                        if(result.data.update_num&&result.data.update_num!==this.updateNum){
                            this.updateNum = result.data.update_num
                        }
                        
                    })
                }
            },20000)
        }
    }
    //更新更新数
    updateUpdateNum(num){
        if(num){
            this.updateNum = num;
        }
        setLocal(baselineName,parseInt(new Date().getTime()/1000))
    }

    async getDongTaiData(){
        let hasMore=true;
        let result = await getDongTaiData(this.pageNum,this.pageSize)
        if(result.status==0){
            runInAction(()=>{
                
                if(result.data.items){
                    if(this.pageNum===1){
                        this.dongtaiMap={}
                        this.dongtaiData = result.data.items
                        if(result.data){
                            result.data.items.map((item)=>{
                                this.updateDongtaiMap(item.id)
                            })
                        }
                    }else{
                        let newData=[]
                        result.data.items.map((item)=>{
                            let ok = this.updateDongtaiMap(item.id)
                            if(ok){
                                newData.push(item)
                            }
                        })
                        this.dongtaiData = this.dongtaiData.concat(newData)
                    }
                }
                
            })
            hasMore = result.data.has_more
        }

        return {status:result.status,msg:result.msg,hasMore}
    }

    //删除
    async deleteItem(id,userid){
        let result = await delMblog(id,userid)
        if(result.status===0){
            runInAction(()=>{
                this.dongtaiData = this.dongtaiData.filter((item)=>{
                    return item.id!=id;
                })
            })
        }
        return result
        
    }
}


export default DongTaiStore;