import styles from './video.module.less';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBar, Toast } from 'antd-mobile';
import IButton from '../../../UI/Button/button';
import {videoRoutesPath} from '../../../routers/config';
import Head from '../../../assets/img/background.png'
import { inject, observer } from 'mobx-react';
import { getLocal, removeLcal } from '../../../utils/store';
import { longUploadVideoUrlKey } from '../../../config';


const VideoUpload = (props) => {
    const navigate = useNavigate()
    const inputRef = useRef()
    const handleClick = () => {
        inputRef.current.click()
    }
    const VideoUploadId = getLocal(longUploadVideoUrlKey)
    let { VideoUploadStore } = props
    console.log("VideoUploadId")
    // console.log("VideoUploadStore",VideoUploadStore,props)
    // let { updateVideo } = VideoUploadStore
    return (
        <>
            <div className={styles.header}>
                <NavBar
                    mode="dark"
                    icon={<div className={styles.icon}></div>}
                    onLeftClick={() => navigate(-1)}
                >
                    上传视频
                </NavBar>
            </div>
            <div className={styles.body}>
                {VideoUploadId ? <div>
                    <div className={styles.tip}>
                        <p>本地视频存在一个未提交得视频</p>
                        <div className={styles.btn} style={{backgroundColor:"#fff",color:'var(--adm-color-primary)'}}
                            onClick={() => navigate(videoRoutesPath.videoUpload)}>继续</div>
                        <div className={styles.btn}>不用了</div>
                    </div>
                </div> : <></>}
                <div className={styles.content} onClick={handleClick} >
                    <IButton color='primary' size='large'>
                        上传视频
                    </IButton>
                    <input ref={inputRef} accept=".mp4" type="file" style={{ display: 'none', width: "100%", height: "100%" }} onChange={async (event) => {
                        if(!event.target.files[0]){
                            Toast.show("请选择视频");
                            return
                        }
                        console.log("console.log(videoRoutesPath.videoUpload)",videoRoutesPath.videoUpload)
                        //将选择好的视频保存
                        VideoUploadStore.updateVideo(event.target.files[0]);
                        VideoUploadStore.updateVideoName(event.target.files[0].name)

                        navigate(videoRoutesPath.videoUpload)
                        
                        removeLcal(longUploadVideoUrlKey)
                        event.target.value = ''
                    }}></input>
                </div>
                <div>
                    视频大小限制500MB,推荐使用mp4格式上传
                </div>
                <div>
                    请勿上传色情，反动等违法视频。
                </div>
                {/* <div>
                    <video ref={videoRef} src={filePath} style={{ width: "100%", height: "auto" }}></video>
                </div> */}
                {/* <IButton onClick={()=>{
                    handleVideo();
                    drawImage();
                }}>加一</IButton> */}

                {/* {<img src={imgSrc} style={{ width: "100%", height: "auto" }}></img>} */}
                {/* <canvas id="canvas" ref={canvasRef} width={videoRef.current.videoWidth} height={videoRef.current.videoHeight} style={{objectFit:"cover"}} ></canvas> */}
            </div>
        </>

    )
}

export default inject('VideoUploadStore')(observer(VideoUpload));