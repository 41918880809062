/*
 * @Author: shangshanzhishui photoncomputer@163.com
 * @Date: 2023-07-18 15:50:27
 * @LastEditors: shangshanzhishui photoncomputer@163.com
 * @Description: main
 */

import React, { useEffect, useState } from 'react'
import styles from './main.module.less'
import { Badge, TabBar } from 'antd-mobile'
import { useNavigate,Outlet, useLocation } from 'react-router-dom'
import routePath, { needLoginPath } from '../../routers/config'

import {
    HomeIcon,
    DontTaiIcon,
    UploadIcon,
    MsgIcon,
    MineIcon
} from '../../compoents/IconFonts/iconfont'
import { inject, observer } from 'mobx-react'
const Main = (props)=>{
  let {Select,Msg,DongTai}=props
  const navigate = useNavigate();
  const location = useLocation()
  const [activeKey, setActiveKey] = useState('home')
  //处理未读消息数
  let {unreadLikeNum,unreadCommentNum} = Msg;
  let [unreadNum,setUnreadNum] = useState((unreadLikeNum+unreadCommentNum))
  let [dongtaiUpdateNum,setDongtaiUpdateNum] = useState((DongTai.updateNum))
  //监听消息未读数
  useEffect(()=>{
    setUnreadNum((pre)=>{
      return unreadLikeNum+unreadCommentNum;
    })
  },[unreadLikeNum,unreadCommentNum])
  
  //监听动态更新数
  useEffect(()=>{
    setDongtaiUpdateNum((pre)=>{
      return DongTai.updateNum;
    })
  },[DongTai.updateNum])
  function shouNun(num){
    if(num>0){
      if(num>99){
        return '99+'
      }
      return unreadLikeNum
    }
    return null
  }
  
  // console.log(location,'aaa',activeKey)
  let routeInfo={
    "home":Select.select,
    "dongtai":routePath.dongtai,
    "upload":routePath.upload,
    "msg":routePath.message,
    "mine":routePath.mine,
  }
  useEffect(()=>{
    const pathname = location.pathname;
    let pathList = pathname.split('/')
    // console.log(pathList)
    if(pathList[1]!==activeKey){
      setActiveKey(pathList[1])
    }
  },[location.pathname]);
  //改变页面显示
  function changePage(value){
    setActiveKey(value)
    navigate(needLoginPath(routeInfo[value]))
  }
    const tabs = [
        {
          key: 'home',
          title: '首页',
          icon: <HomeIcon />,
        },
        {
          key: 'dongtai',
          title: '动态',
          icon: <DontTaiIcon />,
          badge: shouNun(dongtaiUpdateNum),
        },
        {
          key: 'upload',
          title: '添加',
          icon: <UploadIcon  fontSize='30px'/>,
        },
        {
          key: 'msg',
          title: '消息',
          icon: <MsgIcon />,
          badge: shouNun(unreadNum),
        },
        {
          key: 'mine',
          title: '我的',
          icon: <MineIcon />,
        },
      ]

      return (
        <div className={styles.body}>
            
            <div className={styles.main}>
            <Outlet />
            </div>
            
            <TabBar className={styles.bottom} activeKey={activeKey}   onChange={value=>changePage(value)} style={{backgroundColor:Select.select===routePath.shortVideoHome?'black':'whrite',padding:0}}>
                {tabs.map(item=>{
                    return <TabBar.Item key={item.key} icon={item.icon} title={item.key==="upload"?null:item.title} badge={item.badge}></TabBar.Item>
                })}
            </TabBar>
        </div>
            
        
        
      );
}

// export default Main;
export default inject("Select",'Msg',"DongTai")(observer(Main));