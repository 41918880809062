import { makeAutoObservable,runInAction, toJS } from "mobx";
import { getUserData } from "../../api/user/user";
import { delMblog, getMineBlogData } from "../../api/mblog/mblog";
import head from '../../assets/img/headimg.png'
import backgroundImg from "../../assets/img/background.png"
import { setLocal } from "../../utils/store";
import { userIdName } from "../../config";

class MineStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    userData = {};
    pageNum=1;
    pageSize = 10;
    mineMBlogData=[];
    mineMBlogMap = {}
    updatePageNume(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
        
    }

    //更新去重map
    updateMineMBlogMap(id){
        if(this.mineMBlogMap[id]){
            return false
        }else{
            this.mineMBlogMap[id] = 1
            return true
        }
    }

    async getUserData(){
        if(Object.keys(this.userData)>0){
            return
        }
        let result = await getUserData();
        if(result.status==0){
            runInAction(() => {
                let userData={}
                userData.nickname= result.data.nickname?result.data.nickname:"";
                userData.accountName= result.data.account_name?result.data.account_name:"";
                userData.headImg= result.data.headImg?result.data.headImg:head;
                userData.backgroundImg= result.data.backgroundimg?result.data.backgroundimg:backgroundImg;
                userData.userid= result.data.userid?result.data.userid:0;
                userData.sex= result.data.sex?result.data.sex:0;
                userData.birthday= result.data.birthday?result.data.birthday:0;
                userData.remark= result.data.remark?result.data.remark:"";
                userData.zanNum= result.data.zan_num?result.data.zan_num:0;
                userData.fansNum= result.data.fans_num?result.data.fans_num:0;
                userData.guanZhuNum= result.data.follow_num?result.data.follow_num:0;
                userData.phone= result.data.phone?result.data.phone:"";
                userData.tag= result.data.tag?result.data.tag:"";
                this.userData = userData;
                setLocal(userIdName,userData.userid)
        })
        
        }
        return {status:result.status,msg:result.msg}
        
    }

    updateUserData(key,value){
        let userData = {...this.userData}
        userData[key]=value;
        this.userData = userData;
    }

    async getMineMicroBlogData(){
        let result = await getMineBlogData(this.pageNum,this.pageSize);
        runInAction(()=>{
            if(this.pageNum===1){
                this.mineMBlogData = result.data
                this.mineMBlogMap={}
                if(Array.isArray(result.data)){
                    result.data.map((item)=>{
                        this.updateMineMBlogMap(item.id)
                    })
                }
            }else{
                let newData=[]
                if(Array.isArray(result.data)){
                    result.data.map((item)=>{
                        let ok = this.updateMineMBlogMap(item.id)
                        if(ok){
                            newData.push(item)
                        }
                    })
                }
                this.mineMBlogData = this.mineMBlogData.concat(newData)
            }
        })
        
        return {status:result.status,msg:result.msg,hasMore:result.data.length=== this.pageSize}
        
    }

    
    //删除
    async deleteItem(id,userid){
        let result = await delMblog(id,userid)
        if(result.status===0){
            runInAction(()=>{
                this.mineMBlogData = this.mineMBlogData.filter((item)=>{
                    return item.id!=id;
                })
            })
        }
        return result
        
    }
}

export default MineStore;