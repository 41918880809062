import { makeAutoObservable, runInAction } from "mobx";
import { delMblog, getMBlogData } from "../api/mblog/mblog";

class MBlogStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    //内容数据
    blogData =[];
    pageNum =1;
    pageSize = 10;
    mblogMap = {};
    updatePageNume(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
        
    }
    //更新去重map
    updateMblogMap(id){
        if(this.mblogMap[id]){
            return false
        }else{
            this.mblogMap[id] = 1
            return true
        }
    }
    async getData() {
        let result = await getMBlogData(this.pageNum,this.pageSize)
        let hasMore = true;
        if(result.status===0){
            runInAction(()=>{
                if(this.pageNum===1){
                    this.mblogMap={}
                    
                    if(result.data){
                        this.blogData = result.data;
                        result.data.map((item)=>{
                            this.updateMblogMap(item.id)
                        })
                    }
                }else{
                    if(Array.isArray(result.data)){
                        let newData=[]
                        
                        result.data.map((item)=>{
                            let ok = this.updateMblogMap(item.id)
                            if(ok){
                                newData.push(item)
                            }
                        })
                        this.blogData = this.blogData.concat(newData)
                    }
                }
            })
        }
        
        if(Array.isArray(result.data)&&result.data.length<this.pageNum){
            hasMore = false
        }
        let status = 0
        if(result.status==undefined){
            status = 1
        }
        
        return {status:result.status,msg:result.msg,hasMore}
    }

    //删除
    async deleteItem(id,userid){
        let result = await delMblog(id,userid)
        if(result.status===0){
            runInAction(()=>{
                this.blogData = this.blogData.filter((item)=>{
                    return item.id!=id;
                })
            })
        }
        return result
        
    }

    //更新评论数
    updateCommentNum(blogId,num){
        if(!num){
            num = 1
        }
        for (let index = 0; index < this.blogData.length; index++) {
            const element = this.blogData[index];
            if(element.id===blogId){
                element.comment_num+=num;
                if(element.comment_num<0){
                    element.comment_num = 0
                }
            }
        }
    }
    //更新点赞数数
    updateLikeNum(blogId,num){
        if(!num){
            num = 1
        }
        for (let index = 0; index < this.blogData.length; index++) {
            const element = this.blogData[index];
            if(element.id===blogId){
                element.like_num+=num;
                if(element.like_num<0){
                    element.like_num = 0
                }
            }
        }
    }

}
export default MBlogStore;