import { makeAutoObservable, runInAction } from "mobx";
import { requestUserVideoList } from "../../api/video/video";

class UserVideoStore{
    constructor(root){
        this.root=root;
        makeAutoObservable(this);
    }

    videoList = []
    pageNum = 1;
    pageSize = 5;
    VideoMap = {};
    updatePageNum(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
    }
    //更新去重map
    updateVideoMap(id){
        if(this.VideoMap[id]){
            return false
        }else{
            this.VideoMap[id] = 1
            return true
        }
    }
    //通过id获取视频信息
    getVideoById(id){
        const index = this.videoList.findIndex((item)=>{
            return item.id == id
        })
        if (index<0){
            return 0
        }
        return index
    }
    //获得视频列表
    async getVideoList(userid){
        console.log("this.nnn3",this.pageNum,userid)
        let result = await requestUserVideoList(this.pageNum,this.pageSize,userid);
        console.log("this.nnn",this.pageNum)
        if(result.status===0){
            console.log("this.nnn2",this.pageNum)
            runInAction(()=>{
                if(this.pageNum===1){
                    this.VideoMap = {};
                    if(result.data){
                        this.videoList = result.data;
                        result.data.forEach(item=>{
                            // let info = {
                            //     "id": "",
                            //     "title": "",
                            //     "desc": "",
                            //     "video": {
                            //         "width": 0,
                            //         "height": 0,
                            //         "url": "",
                            //         "cover": ""
                            //     },
                            //     "author": {
                            //         "userid": 0,
                            //         "nickname": "",
                            //         "headimg": ""
                            //     },
                            //     "statistics": {
                            //         "like_num": 0,
                            //         "comment_num": 0,
                            //         "share_num": 0,
                            //         "play_num": 0
                            //     },
                            //     "state": {},
                            //     "is_top": false,
                            //     "create_time": ""
                            // }
                            this.updateVideoMap(item.id);
                        })
                    }

                }else{
                    if(result.data){
                        let newData=[]
                        result.data.forEach(item=>{
                            let ok = this.updateVideoMap(item.id);
                            if(ok){
                                newData.push(item)
                            }
                        })
                        this.videoList = this.videoList.concat(newData);
                    }
                }
                if(Array.isArray(result.data)&&result.data.length>=this.pageSize){
                    this.pageNum+=1
                }
            })
        }
        return {status:result.status,msg:result.msg,hasMore:result.has_more};
    }

}

export default UserVideoStore;