/*
 * @Author: shangshanzhishui photoncomputer@163.com
 * @Date: 2023-07-17 15:17:03
 * @LastEditors: shangshanzhishui photoncomputer@163.com
 * @LastEditTime: 2023-07-19 14:04:37
 * @FilePath: \findor\src\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { BrowserRouter } from 'react-router-dom';
import {KeepAliveProvider} from 'keepalive-react-component';
import './App.css';
import RouterView from './routers' 
import { refreshToken } from './api/auth/auth';
import { clear, setLocal } from './utils/store';
import { tokenName } from './config';
function App() {
  refreshToken().then((result)=>{
    if(result.data.login_status==="no"){
      clear()
    }else if(result.status===0&&result.data.token){
      setLocal(tokenName,result.data.token)
    }
  })
  return (
    <BrowserRouter>
      <KeepAliveProvider>
        <RouterView/>
      </KeepAliveProvider>
      
    </BrowserRouter>
  );
}

export default App;
