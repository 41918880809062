import { lazy } from 'react';
import Main from '../pages/Main/main'
import { Navigate } from 'react-router-dom';
import Login from '../pages/Login/login';
import LoginPwd from '../pages/Login/loginPwd';
import Upload from '../pages/Upload/upload';
import { withKeepAlive } from 'keepalive-react-component';
import Message from '../pages/Message/message'
import routePath, { shortVideoRoutesPath, videoRoutesPath, toWhere } from './config';

const shortVideoRoutes = [
    {
        path: shortVideoRoutesPath.shortVideoHome,
        name: 'shortVideoHome',
        component: lazy(() => import('../pages/ShortVideo/shortVideo')),
        meta: {
            title: "短视频"
        },
    }, {
        path: shortVideoRoutesPath.shortVideoUpload,
        name: 'shortVideoPublish',
        component: lazy(() => import('../pages/Upload/shortVideo/publish/publish')),
        meta: {
            title: "上传"
        },
    }, {
        path: shortVideoRoutesPath.coverChange,
        name: 'shortVideoOverChange',
        component: lazy(() => import('../pages/common/coverSelect/coverSelect')),
        meta: {
            title: "封面"
        },
    }
]
const videoRoutes = [
    {
        path: videoRoutesPath.videoHome,
        name: 'videoHome',
        component: lazy(() => import('../pages/Video/video')),
        meta: {
            title: "视频"
        },
        children: []
    },
    {
        path: videoRoutesPath.detail,
        name: 'vdetail',
        component: lazy(() => import('../compoents/player/video/videoDesc')),
        meta: {
            title: ""
        },
    }
    , {
        path: videoRoutesPath.videoUpload,
        name: 'videoPublish',
        component: lazy(() => import('../pages/Upload/video/publish/publish')),
        meta: {
            title: "上传"
        },
    }, {
        path: videoRoutesPath.videoCoverChange,
        name: 'videoOverChange',
        component: lazy(() => import('../pages/common/coverSelect/coverSelect')),
        meta: {
            title: "封面"
        },
    }
]

const routesInfo = [{
    path: '/',
    mame: 'main',
    component: Main,
    meta: {
        title: "不知道叫什么"
    },
    children: [
        {
            path: '',
            component: () => <Navigate to={toWhere()} />,
            meta: {
                title: "home"
            },
            children: []
        }, {
            path: routePath.home,
            mame: 'home',
            component: withKeepAlive(lazy(() => import('../pages/Union/union')), { cacheId: 'union', scroll: true }),
            meta: {
                title: "综合"
            },
            children: []
        }, {
            path: routePath.microBlog,
            mame: 'mblog',
            component: withKeepAlive(lazy(() => import('../pages/MicroBlog/mBlog')), { cacheId: 'mblog', scroll: true }),
            meta: {
                title: "短文"
            },
            children: [

            ]
        },
        ...shortVideoRoutes,
        ...videoRoutes,
        //动态
        {
            path: routePath.dongtai,
            mame: 'dongtai',
            component: withKeepAlive(lazy(() => import('../pages/DongTai/dongTai')), { cacheId: 'dongtai', scroll: true }),
            meta: {
                title: "dongtai"
            },
            children: []
        },
        //消息
        {
            path: 'msg',
            mame: 'msg',
            // component:lazy(()=>import('../pages/Message/message')),
            component: withKeepAlive(Message, { cacheId: 'msg', scroll: true }),
            meta: {
                title: "消息"
            },
            children: []
        }, {
            path: routePath.mine,
            mame: 'mine',
            component: withKeepAlive(lazy(() => import('../pages/Mine/mine')), { cacheId: 'mine', scroll: true }),
            meta: {
                title: "主页"
            },
            children: [

            ]
        }
    ]
},
, {
    path: routePath.upload,//发布上传
    name: 'upload',
    component: Upload,
    meta: {
        title: '发布'
    },
    children: []
},
{
    path: '/login',//登录页
    name: 'login',
    component: Login,
    meta: {
        title: '登录'
    },
    children: []
}, {
    path: routePath.loginPwd,//密码登录
    name: 'loginpwd',
    component: LoginPwd,
    meta: {
        title: '密码登录'
    },
    children: []
}, {
    path: routePath.receivedComment,//收到的评论
    name: 'receivedComment',
    component: withKeepAlive(lazy(() => import('../pages/Message/comment/receivedComment')), { cacheId: 'receivedComment', scroll: true }),
    meta: {
        title: '收到的评论'
    },
    children: []
}, {
    path: routePath.receivedLike,//收到的赞
    name: 'receivedLike',
    component: withKeepAlive(lazy(() => import('../pages/Message/like/receivedLike')), { cacheId: 'receivedLike', scroll: true }),
    meta: {
        title: '收到的赞'
    },
    children: []
},

{
    path: routePath.microBlogDetail,//详情页
    name: 'mblogDetail',
    component: lazy(() => import('../pages/details/mbLog/mblogDetails')),
    meta: {
        title: "详情"
    },
},

{
    path: routePath.userSpace,
    name: 'user',
    component: lazy(() => import('../pages/user/user')),
    meta: {
        title: "主页"
    },
    children: []
}, {
    path: routePath.minesetting,//个人信息设置页
    name: 'mine',
    component: lazy(() => import('../pages/Mine/edit/setting')),
    meta: {
        title: "个人信息"
    },
    children: [

    ]
},

////头像
{
    path: routePath.headimg,
    name: 'headimg',
    component: lazy(() => import('../pages/Mine/edit/headimg/headimg')),
    meta: {
        title: "头像"
    },
    children: []
},
{
    path: routePath.backgroundimg,//背景图
    name: 'headimg',
    component: lazy(() => import('../pages/Mine/edit/background/backgroundimg')),
    meta: {
        title: "头像"
    },
    children: []
},

{
    path: routePath.about,//关于
    name: 'about',
    component: lazy(() => import('../pages/Other/aboutMe')),
    meta: {
        title: "关于"
    },
    children: []
},

{
    path: routePath.setting,//设置
    name: 'setting',
    component: lazy(() => import('../pages/Setting/setting')),
    meta: {
        title: "关于"
    },
    children: []
},

{
    path: "*",
    name: '404',
    component: lazy(() => import('../pages/Other/404')),
    meta: {
        title: "页面不存在"
    },
},
    // ...shortVideoRoutes,
];




export default routesInfo;