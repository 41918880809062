import { makeAutoObservable } from "mobx";

class VideoUploadStore{
    constructor(root){
        this.root=root;
        makeAutoObservable(this);
    }
    selectedVideo = null;
    videoName = null;
    updateVideo(Video){
        this.selectedVideo=Video;
    }
    updateVideoName(name){
        this.videoName=name;
    }
    clearVideo(){
        this.selectedVideo=null;
        this.videoName=null;
    }

}

export default VideoUploadStore;