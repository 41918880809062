/*
 * @Author: shangshanzhishui photoncomputer@163.com
 * @Date: 2023-07-16 21:35:13
 * @Description: Button组件
 */
import { Button } from "antd-mobile";
const IButton = function(props){
    return <Button {...props}></Button>
}

export default IButton