import {TextArea,NavBar,Toast } from "antd-mobile";
import styles from './microBlog.module.less'
import IImageUploader from "../../../compoents/image/imageUpload";
import { uploadImage,deleteImage ,createMBlog} from "../../../api/mblog/mblog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocal, removeLcal, setLocal } from "../../../utils/store";
import routePath from "../../../routers/config";
const MicroBlogUpload = ()=>{
    let [imgShowType,setImgShowTpe] = useState('grid')
    let blogImages = getLocal("blogImages")
    blogImages = blogImages?JSON.parse(blogImages):[]
    const navigate = useNavigate()
    let [fileList,setFileList] = useState(blogImages)
    //获得保存好的还没发布的内容
    let textContent = getLocal("blogContent")
    textContent = textContent?textContent:"";
    function hadleSelectSHowType(type) {
        setImgShowTpe(type);
    }
    function handleDelete(id){
        deleteImage(id)
    }
    async function handleUpload(files){
        let result = await uploadImage(files)
        if(result.data.warning){
            Toast.show({content:result.data.warning})
        }
        if(result.status===1){
            Toast.show({content:result.data.msg})
        }
        return result.data.img_list
    }
    
    async function publish(){
        let images = []
        fileList.map((item)=>{
            images.push(item.id)
        })
        let showType = "book"
        if(imgShowType=="grid"){
            showType = "balance"
        }
        // console.log("contentcontent",textContent,getLocal("blogContent"),images)
        let result = await createMBlog(textContent,images,showType)
        // console.log("contentcontent",textContent,result)
        if(result.status===0){
            removeLcal("blogContent")
            removeLcal("blogImages")
            navigate(routePath.dongtai)
        }else{
            Toast.show({content:result.msg})
        }
    }
    
    
    return <div className={styles.image_upload_space}>
            
            <NavBar onBack={()=>{navigate(-1)}}>
                <div className={styles.head_content }>
                <div className={styles.select_button} style={{color:imgShowType==="grid"?"var(--adm-color-primary)":"black"}} onClick={()=>{

                    hadleSelectSHowType('grid')}} >九宫格</div>
                <div className={styles.select_button} style={{color:imgShowType==="big"?"var(--adm-color-primary)":"black"}}  onClick={()=>{
                    hadleSelectSHowType('big')}}>大图</div>
                <button className={styles.fabu_button} type="button" onClick={()=>{
                    publish()
                }} ><div>发布</div></button>
                </div>
                
                
            </NavBar>
            <div className={styles.textarea}>
            <TextArea 
                placeholder='请分享你的想法'
                autoSize={{ minRows: 5, maxRows: 7 }}
                showCount
                maxLength={1000}
                defaultValue={textContent}
                onChange={(value)=>{
                    textContent = value
                    setLocal("blogContent",value)
                }}

            />
            </div>
            
            <div className={styles.img_show}>
                <IImageUploader showType={imgShowType}
                    upload={handleUpload}
                    delete = {handleDelete}
                    fileList = {fileList}
                    onChange = {(data)=>{
                        setFileList((s)=>{
                            setLocal("blogImages",JSON.stringify(data))
                            return data
                        })
                    }}
                />
            </div>
    </div>
}


export default MicroBlogUpload;