import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'mobx-react'
import '../src/assets/iconFonts/iconfont'

import store from './store';

let r = document.getElementById('root')


var script = document.createElement('script')
script.innerHTML=`function d(){
  const computed = ()=>{
      let html = document.documentElement,
      deviceW = html.clientWidth,
      designW = 750;
      if(deviceW>designW){
          html.style.fontSize = '100px';
          return;
      }
      let ratio = deviceW / designW;
      html.style.fontSize = ratio * 100 + 'px';
  }
  computed();
  window.addEventListener('resize',computed);
}
console.log("eqewqeqwe");
d();`

r.appendChild(script)
const root = ReactDOM.createRoot(r);
root.render(
  // <React.StrictMode>
    <Provider {...store}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
