import { makeAutoObservable } from "mobx";

class ShortVideoUploadStore{
    constructor(root){
        this.root=root;
        makeAutoObservable(this);
    }
    selectedShortVideo = null;
    videoName = null;
    updateShortVideo(shortVideo){
        this.selectedShortVideo=shortVideo;
    }
    updateShortVideoName(name){
        this.videoName=name;
    }
    clearShortVideo(){
        this.selectedShortVideo=null;
        this.videoName=null;
    }

}

export default ShortVideoUploadStore;