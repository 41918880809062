const tokenName = 'zhuque'
const userIdName = "userid";
const userInfoCacheKey = "user";
const baselineName = "baseline";
const myuserid = 0;

const lastComment = "lastComment"
const lastLike = "lastLike"

const unreadLikeNum = "unreadLikeNum"
const unreadCommentNum = "unreadCommentNum"

//视频分片上传的大小
const chunkSize = 20*1025*1024;

//存放短视频视频上传成功的uploadId
const shortUploadVideoUrlKey = "suvuk";
//存放视频上传成功的uploadId
const longUploadVideoUrlKey = "luvuk";

//存放短视频视频上传时的标题
const shortUploadVideoTitleKey = "svt";
const shortUploadVideoContentKey = "svc";

//存放视频视频上传时的标题
const longUploadVideoTitleKey = "lvt";
const longUploadVideoContentKey = "lvc";

export {
    tokenName,
    userIdName,
    userInfoCacheKey,
    baselineName,
    myuserid,
    lastComment,
    lastLike,
    unreadLikeNum,
    unreadCommentNum,
    chunkSize,
    shortUploadVideoUrlKey,
    shortUploadVideoTitleKey,
    shortUploadVideoContentKey,
    longUploadVideoTitleKey,
    longUploadVideoContentKey,
    longUploadVideoUrlKey

    
}