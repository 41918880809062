import { tokenName } from "../../config";
import { getLocal } from "../../utils/store";
import { videoHttpConfig, videoUploadHttpConfig } from "../config";
import { get, post, postFile, uploadFile } from "../../utils/http2"

var commonResult = { status: 1, 'msg': '出错', data: [] }

//上传视频数据
const uploadVideo = async (file,params,config) => {
    const baseurl = videoUploadHttpConfig.upload;
    let url = new URL(baseurl)
    // const token = getLocal(tokenName)
    // params = Object.assign(params, { token })
    params = new URLSearchParams(Object.entries(params))
    // const d = { file: data, uploadId: "uuuu", partNum, start, chunkSize }
    url.search = params.toString()
    let result = { status: 1, 'msg': '出错', data: [] }
    try {
        result = await uploadFile(url, file, config)
    }catch (error) {

    }


    return result;
}

//合并视频
const mergeVideo = async (params) => {
    const baseurl = videoUploadHttpConfig.upload;
    let url = new URL(baseurl)
    const token = getLocal(tokenName)
    params = Object.assign(params, { token })
    params = new URLSearchParams(Object.entries(params))
    url.search = params.toString()
    let result = { status: 1, 'msg': '出错', data: [] }
    try {
        result = await post(url, {})
    } catch (error) {

    }

    return result
}


//获得视频列表
const requestVideoList = async (pageNum,pageSize) => {
    const baseURL = videoHttpConfig.getVideoList
    let url = new URL(baseURL)
    let urlParams = {
        pageNum,
        pageSize
    }
    url.search = new URLSearchParams(Object.entries(urlParams)).toString()
    let result = commonResult
    try {
        result = await get(url)
    }catch (e) {
        console.log(e)
    }
    return result
}
//获得玩家的视频列表
const requestUserVideoList = async (pageNum,pageSize,userid) => {
    const baseURL = videoHttpConfig.getUserVideoList
    let url = new URL(baseURL)
    if(!userid){
        userid = 0
    }
    let urlParams = {
        pageNum,
        pageSize,
        userid
    }
    url.search = new URLSearchParams(Object.entries(urlParams)).toString()
    let result = commonResult
    try {
        result = await get(url)
    }catch (e) {
        console.log(e)
    }
    return result
}

//获得视频信息详情
const requestVideoInfo = async (vid) => {
    const baseURL = videoHttpConfig.getVideoInfo
    let url = new URL(baseURL)
    let urlParams = {vid}
    url.search = new URLSearchParams(Object.entries(urlParams)).toString()
    let result = commonResult
    try {
        result = await get(url)
    }catch (e) {
        console.log(e)
    }
    return result
}

//删除视频数据
const deleteVideo = async (params) => {
    const baseurl = videoUploadHttpConfig.deleteVideo;
    let url = new URL(baseurl)
    const token = getLocal(tokenName)
    params = Object.assign(params, { token })
    params = new URLSearchParams(Object.entries(params))
    url.search = params.toString()
    let result = { status: 1, 'msg': '出错', data: [] }
    try {
        result = await post(url, {})
    }
    catch (error) {

    }
    return result
    
}

//视频信息上传
const createVideoInfo = async (uploadId,title,desc,coverFile,videoName) => {
    const baseurl = videoUploadHttpConfig.createInfo;
    let url = new URL(baseurl)
    const token = getLocal(tokenName)
    let result = { status: 1, 'msg': '出错', data: [] }
    const formData = new FormData()
    if(uploadId){
        formData.append('uploadId', uploadId)
    }
    console.log("titletitle",title,desc)
    if(title){
        formData.append('title', title)
    }
    if (desc) {
        formData.append('content', desc)
    }
    formData.append('coverFile', coverFile)
    try {
        result = await post(url, formData)
    }
    catch (error) {

    }
    
    return result
}
const deleteVideoInfo = async (params) => {
    const baseurl = videoUploadHttpConfig.delete;
    let url = new URL(baseurl)
    const token = getLocal(tokenName)
    params = Object.assign(params, { token })
    params = new URLSearchParams(Object.entries(params))
    url.search = params.toString()
    let result = commonResult
    
    try {
        result = await post(url, {})
    }
    catch (error) {

    }
    return result
    
}

export {
    uploadVideo,
    mergeVideo,
    createVideoInfo,
    requestVideoList,
    requestUserVideoList,
    requestVideoInfo
}