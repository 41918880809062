import { baselineName, tokenName } from "../../config"
import http from "../../utils/http"
import { getLocal } from "../../utils/store"
import { dongtaiHttpConfig } from "../config"

//获得未读消息数
const getUpdateNum = async()=>{
    const url = dongtaiHttpConfig.updateNumPath
    const token = getLocal(tokenName)
    let basLine = getLocal(baselineName)
    if(!basLine){
        basLine = parseInt(new Date().getTime()/1000)
    }else{
        basLine = parseInt(basLine)
    }
    let result = {}
    try {
        result = await http.post(url,{token:token,update_baseline:basLine})
    } catch (error) {
        result = {status:1,msg:"出错",data:{}}
    }
    
}

//获得动态数据
const getDongTaiData =async(pageNum,pageSize)=>{
    const url = dongtaiHttpConfig.all
    const token = getLocal(tokenName)
    let result = {}
    try {
        result = await http.post(url,{"token":token,"page_num":pageNum,"page_size":pageSize})
    } catch (error) {
        result = {status:1,msg:"出错"}
    }
    return result
}

export{
    getUpdateNum,
    getDongTaiData
}