import { makeAutoObservable, runInAction } from "mobx";
import { requestShortVideoList, requestUserShortVideoList } from "../../api/shortVideo/shortVideo";

class UserShortVideoStore{
    constructor(root){
        this.root=root;
        makeAutoObservable(this);
    }

    shortVideoList = []
    pageNum = 1;
    pageSize = 10;
    shortVideoMap = {};
    updatePageNum(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
    }
    //更新去重map
    updateShortVideoMap(id){
        if(this.shortVideoMap[id]){
            return false
        }else{
            this.shortVideoMap[id] = 1
            return true
        }
    }
    //通过id获取视频信息
    getShortVideoById(id){
        const index = this.shortVideoList.findIndex((item)=>{
            return item.id == id
        })
        if (index<0){
            return 0
        }
        return index
    }
    //获得视频列表
    async getShortVideoList(userid){
        let result = await requestUserShortVideoList(this.pageNum,this.pageSize,userid);
        let hasMore = true;
        if(result.status===0){
            runInAction(()=>{
                if(this.pageNum===1){
                    this.shortVideoMap = {};
                    if(result.data){
                        this.shortVideoList = result.data;
                        console.log("result.data",result.data)
                        result.data.forEach(item=>{
                            // let info = {
                            //     "id": "",
                            //     "title": "",
                            //     "desc": "",
                            //     "video": {
                            //         "width": 0,
                            //         "height": 0,
                            //         "url": "",
                            //         "cover": ""
                            //     },
                            //     "author": {
                            //         "userid": 0,
                            //         "nickname": "",
                            //         "headimg": ""
                            //     },
                            //     "statistics": {
                            //         "like_num": 0,
                            //         "comment_num": 0,
                            //         "share_num": 0,
                            //         "play_num": 0
                            //     },
                            //     "state": {},
                            //     "is_top": false,
                            //     "create_time": ""
                            // }
                            this.updateShortVideoMap(item.id);
                        })
                    }

                }else{
                    if(result.data){
                        let newData=[]
                        this.shortVideoList = this.shortVideoList.concat(result.data);
                        result.data.forEach(item=>{
                            let ok = this.updateShortVideoMap(item.id);
                            if(ok){
                                newData.push(item)
                            }
                        })
                        this.shortVideoList = this.shortVideoList.concat(newData);
                    }
                }
            })
        }
        return {status:result.status,msg:result.msg,hasMore:result.hasMore};
    }

}

export default UserShortVideoStore;