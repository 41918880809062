import { makeAutoObservable, runInAction } from "mobx";
import { requestVideoList } from "../../api/video/video";

class VideoStore{
    constructor(root){
        this.root=root;
        makeAutoObservable(this);
    }

    videoList = []
    pageNum = 1;
    pageSize = 10;
    videoMap = {};
    updatePageNum(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
    }
    //更新去重map
    updateVideoMap(id){
        if(this.videoMap[id]){
            return false
        }else{
            this.videoMap[id] = 1
            return true
        }
    }
    //获得视频列表
    async getVideoList(){
        let result = await requestVideoList(this.pageNum,this.pageSize);
        let hasMore = true;
        if(result.status===0){
            runInAction(()=>{
                if(this.pageNum===1){
                    this.videoMap = {};
                    if(result.data){
                        this.videoList = result.data;
                        console.log("result.data",result.data)
                        result.data.forEach(item=>{
                            // let info = {
                            //     "id": "",
                            //     "title": "",
                            //     "desc": "",
                            //     "video": {
                            //         "width": 0,
                            //         "height": 0,
                            //         "url": "",
                            //         "cover": ""
                            //     },
                            //     "author": {
                            //         "userid": 0,
                            //         "nickname": "",
                            //         "headimg": ""
                            //     },
                            //     "statistics": {
                            //         "like_num": 0,
                            //         "comment_num": 0,
                            //         "share_num": 0,
                            //         "play_num": 0
                            //     },
                            //     "state": {},
                            //     "is_top": false,
                            //     "create_time": ""
                            // }
                            this.updateVideoMap(item.id);
                        })
                    }

                }else{
                    
                    if(result.data){
                        let newData=[]
                        result.data.forEach(item=>{
                            let ok = this.updateVideoMap(item.id);
                            if(ok){
                                newData.push(item)
                            }
                        })
                        this.videoList = this.videoList.concat(newData);
                    }
                }
                if(result.has_more){
                    this.pageNum+=1
                }
            })
            console.log(this.videoList,result.data,this.pageNum)
        }
        return {status:result.status,msg:result.msg,hasMore:result.has_more};
    }

}

export default VideoStore;