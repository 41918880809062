import http from "../../utils/http";
import { adHttpConfig } from "../config";
//banner广告
const  getBannerAd = async ()=>{
    const url = adHttpConfig.banner;
    let result = {};
    try {
        result = await http.get(url);
    } catch (error) {

    }
    return result;
}

export {
    getBannerAd
}