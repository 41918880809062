import { makeAutoObservable, runInAction } from "mobx";
import { getMblogComment } from "../api/mblog/mblog";

class CommentStore{
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }
    commentData = []
    commentMap ={}
    id=""
    pageNum=1;
    pageSize=10;
    updatePageNum(num){
        if(!num){
            this.pageNum+=1;
        }else{
            this.pageNum = num;
        }
    }
    //更新去重map
    updateCommentMap(commentId){
        console.log("this.commentMap",this.commentMap,commentId,this.commentMap[commentId])
        if(this.commentMap[commentId]){
            return false
        }else{
            this.commentMap[commentId] = 1
            return true
        }
    }

    async getCommentData(id){
        console.log("进了吧1")
        let result = await getMblogComment(id,this.pageNum,this.pageSize)
        console.log("进了吧2")
        let hasMore = true;
        let commentNum = 0
        if(result.status===0){
            hasMore=result.data.has_more
            commentNum = result.data.comment_num
            
            runInAction(()=>{
                if(id!==this.id){
                    this.commentMap={}
                    this.commentData=result.data.data
                    if(result.data.data){
                        result.data.data.map((item)=>{
                            this.updateCommentMap(item.comment_id)
                        })
                    }
                }else if(this.pageNum===1){
                    this.commentData=result.data.data
                    this.commentMap={}
                        result.data.data.map((item)=>{
                            this.updateCommentMap(item.comment_id)
                        })
                }else{
                    let newData=[]
                    result.data.data.map((item)=>{
                        let ok = this.updateCommentMap(item.comment_id)
                        if(ok){
                            newData.push(item)
                        }
                    })
                    this.commentData = this.commentData.concat(newData)
                }
                this.id = id;
            })

        }
        
        return {status:result.status,msg:result.msg,commentNum:commentNum,hasMore:hasMore}
    }

    clearCommentData(id){
        if(id!==this.id){
            this.commentData = []
        }
        
    }

        //删除
        deleteItem(commentId){
            this.commentData.filter((item)=>{
                return item.id!=commentId;
            })
        }
}

export default CommentStore;