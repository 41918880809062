import { authHttpConfig } from "../config"
import http from "../../utils/http";
import {getLocal} from "../../utils/store"
import {tokenName} from "../../config"
//密码登录
const loginPwd = async (phone,pwd)=>{
    const url = authHttpConfig.loginPwd;
    let result = {};
    try {
        result = await http.post(url,{phone,pwd});
    } catch (error) {
        result = {status:1,msg:"出错",data:{}}
    }
    return result;
}
//注册
const register = async (phone,pwd)=>{
    const url = authHttpConfig.register;
    let result = {};
    try {
        result = await http.post(url,{phone,pwd});
    } catch (error) {
        result = {status:1,msg:"出错",data:{}}
    }
    return result;
}
//验证码登录
const login = async (phone,code)=>{
    const url = authHttpConfig.loginPhone;
    let result = {};
    try {
        result = await http.post(url,{phone,code});
    } catch (error) {
        result = {status:1,msg:"出错",data:{}}
    }
    return result;
}
//刷新token
const refreshToken = async ()=>{
    const url = authHttpConfig.refreshtoken;
    const token = getLocal(tokenName)
    let result = {};
    try {
        result = await http.post(url,{token});
    } catch (error) {
        result = {status:1,msg:"出错",data:{}}
    }
    return result;
}

export {
    loginPwd,
    register,
    login,
    refreshToken
}