import { Swiper, Tabs, Toast } from "antd-mobile";
import styles from './upload.module.less'
import { useEffect, useRef, useState } from "react";
import MicroBlogUpload from "./microBlog/microBlog";
import ShortVideoUpload from "./shortVideo/shortVideo";
import VideoUpload from "./video/video";
const Upload = () => {
    let [activeIndex, setActiveIndex] = useState(0)
    let tabItems = [
        { key: 0, title: "短文" },
        { key: 1, title: "短视频" },
        { key: 2, title: "视频" },
    ]
    const swiperRef = useRef(null);

    return <>
        <Swiper
            direction='horizontal'
            indicator={() => null}
            defaultIndex={activeIndex}

            onIndexChange={index => {
                console.log("index",index)
                setActiveIndex(index)
                
            }}
            ref={swiperRef}
        >
            <Swiper.Item>
                <MicroBlogUpload />
            </Swiper.Item>
            <Swiper.Item>
                <ShortVideoUpload />
            </Swiper.Item>
            <Swiper.Item>
                <VideoUpload />
            </Swiper.Item>
        </Swiper>
        <div className={styles.tabs}>
            <Tabs defaultActiveKey={tabItems[0].key} activeKey={tabItems[activeIndex].key} onChange={key => {
                // const index = tabItems.findIndex(item => item.key === key)
                console.log("index2",key)
                setActiveIndex(key)
                swiperRef.current?.swipeTo(key)
                console.log(swiperRef.current)
            }
            }>
                {tabItems.map(item => {
                    return <Tabs.Tab title={item.title} key={item.key}></Tabs.Tab>
                })}
            </Tabs>
        </div>
    </>

}


export default Upload;