function setLocal(key,value){
    if(typeof value !=='string'){
        value = JSON.stringify(value)
    }
    localStorage.setItem(key,value)
}

function getLocal(key){
    return localStorage.getItem(key)
}
function removeLcal(key){
    return localStorage.removeItem(key)
}
function key(key){
    return localStorage.key(key)
}

function clear(){
    localStorage.clear();
}

export {
    setLocal,
    getLocal,
    removeLcal,
    key,
    clear
}