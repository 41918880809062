import { messageHttpConfig, microBlogHttpConfig } from "../config"
import {http,postFile} from "../../utils/http"
import { getLocal } from "../../utils/store"
import { tokenName } from "../../config"
import {post} from "../../utils/http2"
const getMBlogData = async(pageNum,pageSize)=>{
    const url = microBlogHttpConfig.data
    const token = getLocal(tokenName)
    let result={};
    try {
        
        result = await post(url,{"token":token,"pageNum":pageNum,"pageSize":pageSize})
        console.log("pppppppppppppppppppppppp",result)
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result

}

//获取自己的数据
const getMineBlogData = async(pageNum,pageSize)=>{
    const url = microBlogHttpConfig.mineData
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{"token":token,"pageNum":pageNum,"pageSize":pageSize})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result

}


//获取他人的
const getUserBlogData = async(userid,pageNum,pageSize)=>{
    userid = parseInt(userid)
    const token = getLocal(tokenName)
    const url = microBlogHttpConfig.userData
    let result={};
    try {
        result = await post(url,{token:token,"userid":userid,"pageNum":pageNum,"pageSize":pageSize})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result

}

//获得详情
const getMblogDetail = async(blogId)=>{
    const url = microBlogHttpConfig.detail
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{"token":token,"blog_id":blogId})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result
}

//删除
const delMblog = async(blogId,userid)=>{
    const url = microBlogHttpConfig.delete
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{"token":token,"blog_id":blogId,"userid":String(userid)})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result
}

//获得评论
const getMblogComment = async(blogId,pageNum,pageSize)=>{
    const url = microBlogHttpConfig.comment
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{"token":token,"blog_id":blogId,"page_num":pageNum,"pageSize":pageSize})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result
}

//点赞
const createComment = async(blogId,content)=>{
    const url = microBlogHttpConfig.createComment
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{"token":token,"blogid":blogId,content:content})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result
}
//点赞
const like = async(blogId,userid)=>{
    const url = microBlogHttpConfig.like
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{"token":token,"blogid":blogId})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result
}
//取消点赞
const unLike = async(blogId)=>{
    const url = microBlogHttpConfig.unlike
    const token = getLocal(tokenName)

    let result={};
    try {
        result = await post(url,{"token":token,"blogid":blogId})
    } catch (error) {
        result = {status:1,'msg':'出错',data:{}}
    }
    
    return result
}

//上传图片
const  uploadImage = async (files)=>{
    const url = microBlogHttpConfig.uploadImage
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await postFile(url,{token:token,files:files})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result
}

//上传图片
const  createMBlog = async (content,images,showType)=>{
    const url = microBlogHttpConfig.create
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{token:token,images:images,content:content,showType:showType})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result
}

//删除图片
const  deleteImage = async (images)=>{
    const url = microBlogHttpConfig.deleteImage
    const token = getLocal(tokenName)
    let result={};
    try {
        result = await post(url,{token:token,images:images})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result
}
//举报
const  report = async (reportType,desc,userId,blogId)=>{
    const url = microBlogHttpConfig.report
    const token = getLocal(tokenName)
    console.log(userId,blogId)
    let result={};
    try {
        result = await post(url,{token:token,report_type:reportType,desc,userid:String(userId),id:blogId})
    } catch (error) {
        result = {status:1,'msg':'出错',data:[]}
    }
    
    return result
}

export{
    getMBlogData,
    getMineBlogData,
    getUserBlogData,
    getMblogDetail,
    getMblogComment,
    delMblog,
    like,unLike,
    uploadImage,
    deleteImage,
    createMBlog,
    createComment,
    report
}